import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import languageApi from "../../api/language-api";
import { GET_LANGS } from "../actions";
import { getLangsSuccess, getLangsError } from "./actions";

export function* watchGetLangs() {
  yield takeEvery(GET_LANGS, getLangs);
}

const getLangsAsync = async () =>
  await languageApi
    .getLanguages()
    .then((result) => result.data)
    .catch((error) => (error.response ? error.response.data : error.message));

function* getLangs() {
  try {
    const langsResult = yield call(getLangsAsync);
    if (langsResult.success) {
      yield put(getLangsSuccess(langsResult.langs));
    } else {
      yield put(getLangsError(langsResult.errors));
    }
  } catch (error) {
    yield put(getLangsError(error));
  }
}

export default watchGetLangs;
