import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_SERVICE_PATH}/auth-api`,
});

// User
export const signInWithUsernameAndPassword = (payload) => api.post("/", payload);

export default {
  signInWithUsernameAndPassword,
};
