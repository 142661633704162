import { GET_LANGS, GET_LANGS_SUCCESS, GET_LANGS_ERROR } from "../actions";

export const getLangs = (langs) => {
  return {
    type: GET_LANGS,
    payload: [],
  };
};

export const getLangsSuccess = (langs) => {
  return {
    type: GET_LANGS_SUCCESS,
    payload: langs,
  };
};

export const getLangsError = (langs) => {
  return {
    type: GET_LANGS_ERROR,
    payload: [],
  };
};
